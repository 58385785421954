import { PATH_NAMES } from '@const/httpConst';
import { TSimpleStringObj, TTemplateKeys } from '@models/index';

export enum TYPE_OF_TEMPLATE {
  CRM_CLIENT_CAME = 'crmClientCame',
  CRM_RECORD_CANCELED = 'crmRecordCancel',
  COMMON = 'common',
  CLIENTS = 'clients',
  BAD_REVIEW = 'badReviews',
  MISSED_CALLS = 'missedCalls',
}

export enum CLIENTS_EVENTS {
  LOST = 'lost',
  AFTER_VISIT = 'afterVisit',
  NOT_RECORD = 'notRecord',
  LATE_REPLY = 'lateReply',
}

export enum TASK_KEYS_DICT_NAMES {
  CLIENTS = 'clients',
  MISSED_CALS = 'missedCalls',
  BAD_REVIEWS = 'badReviews',
  COMMON_EMPTY = 'common',
}

export const taskKeys: TTemplateKeys = {
  clients: {
    filial_name: 'Имя филиала',
    client_name: 'Имя клиента',
    client_phone: 'Номер клиента',
    date: 'Дата визита',
    start_time: 'Время начала визита',
    master_name: 'Имя мастера',
    services: 'Услуги визита',
    price: 'Стоимость',
  },
  missedCalls: {
    filial_name: 'Имя филиала',
    client_phone: 'Номер клиента',
  },
  badReviews: {
    filial_name: 'Имя филиала',
    client_phone: 'Номер клиента',
    client_visits: 'Количество визитов',
    review_text: 'Текст отзыва',
  },
  crmClientCame: {
    client_name: 'Имя клиента',
    master_name: 'Имя мастера',
    client_phone: 'Номер клиента',
    price: 'Стоимость',
    services: 'Услуги визита',
  },
  crmRecordCancel: {
    client_name: 'Имя клиента',
    cancelled_date: 'Дата отменённой записи',
    master_name: 'Имя мастера',
    client_phone: 'Номер клиента',
    price: 'Стоимость',
    services: 'Услуги визита',
  },
  common: {},
  recordDone: {
    filial_name: 'Имя филиала',
    client_name: 'Имя клиента',
    client_phone: 'Номер клиента',
    date: 'Дата визита',
    start_time: 'Время начала визита',
    master_name: 'Имя мастера',
    services: 'Услуги визита',
    price: 'Стоимость',
    client_history: 'Ссылка на историю посещений в CRM',
  },
};

export const taskPageNameDict: TSimpleStringObj = {
  [PATH_NAMES.TASKS_ALL]: 'Все',
  [PATH_NAMES.TASKS_REGULAR]: 'Регулярные',
  [PATH_NAMES.TASKS_EVENT]: 'При событии',
  [PATH_NAMES.TASKS_CLIENTS]: 'Клиентские',
};

export const weekDays = {
  '1': 'ПН',
  '2': 'ВТ',
  '3': 'СР',
  '4': 'ЧТ',
  '5': 'ПТ',
  '6': 'СБ',
  '7': 'ВС',
};

export enum TASK_TYPE {
  CLIENT = 'client',
  CASE = 'case',
  STAFF = 'staff',
}

export const taskTypes: TSimpleStringObj = {
  [TASK_TYPE.CLIENT]: 'Клиент',
  [TASK_TYPE.CASE]: 'Дело',
  [TASK_TYPE.STAFF]: 'Сотрудники',
};

export const TASK_VARIANTS = {
  [TASK_TYPE.CASE]: {
    class: 'bg-blue-deal_bg text-blue-deal_text',
    text: '💼 Дело',
  },
  [TASK_TYPE.CLIENT]: {
    class: 'bg-aquaSpring text-green1',
    text: '👩🏻 Клиент',
  },
  [TASK_TYPE.STAFF]: {
    class: 'bg-varden text-staff_text',
    text: '👩‍⚕️ Сотрудник',
  },
};

export enum SEND_TIME {
  REGULAR = 'regular',
  EVENT = 'event',
  CLIENTS = 'clients',
}

export const sendTime: TSimpleStringObj = {
  [SEND_TIME.REGULAR]: 'Регулярно',
  [SEND_TIME.EVENT]: 'При событии',
  [SEND_TIME.CLIENTS]: 'Клиентские',
};

export const clientsEvents: TSimpleStringObj = {
  [CLIENTS_EVENTS.LOST]: 'Для потерявшихся',
  [CLIENTS_EVENTS.AFTER_VISIT]: 'После визита',
};

export enum SENDING_PERIODS {
  DAY = 'day',
  EVERY_FEW_DAYS = 'everyFewDays',
  WEEK = 'week',
  MONTH = 'month',
}

export enum MONTH_SEND_DAYS {
  FIRST_DAY = 1,
  LAST_DAY = 31,
}

export enum SEND_TIMES {
  MIDNIGHT = '00:00',
  DEFAULT_TIME = '10:00',
}

export const sendingPeriods: TSimpleStringObj = {
  [SENDING_PERIODS.DAY]: 'Каждый день',
  [SENDING_PERIODS.EVERY_FEW_DAYS]: 'Каждые несколько дней',
  [SENDING_PERIODS.WEEK]: 'Каждую неделю',
  [SENDING_PERIODS.MONTH]: 'Указать дни месяца',
};

export enum EVENTS_TYPES {
  TELEPHONY = 'telephony',
  YCLIENTS = 'yclients',
  BB_INTEGRATION = 'bbIntegration',
  WORK_STATISTICS = 'workStatistics',
  CRM = 'crm',
  RECORD_CANCELLED = 'recordCancelled',
}

export const eventsTypes: TSimpleStringObj = {
  [EVENTS_TYPES.TELEPHONY]: 'Телефония',
  // [EVENTS_TYPES.YCLIENTS]: 'Yclients',
  [EVENTS_TYPES.BB_INTEGRATION]: 'При интеграции с Бьюти Бот',
  // [EVENTS_TYPES.WORK_STATISTICS]: 'Статистика работы',
  [EVENTS_TYPES.CRM]: 'CRM',
};

export const eventsTypesI: TSimpleStringObj = {
  [EVENTS_TYPES.TELEPHONY]: 'Телефония',
  // [EVENTS_TYPES.YCLIENTS]: 'Yclients',
  [EVENTS_TYPES.BB_INTEGRATION]: 'При интеграции с Бьюти Бот I',
  // workStatistics: 'Статистика работы',
  [EVENTS_TYPES.CRM]: 'CRM',
};

export enum TELEPHONY_EVENTS {
  MISSED_CALL = 'missedCall',
  NOT_RECORD_CALL = 'notRecordedCall',
}

export const telephonyEvents: TSimpleStringObj = {
  [TELEPHONY_EVENTS.MISSED_CALL]: 'Пропущенный вызов',
  // [TELEPHONY_EVENTS.NOT_RECORD_CALL]: 'Клиент не записан после звонка',
};

export enum YCLIENTS_EVENTS {
  CLIENT_DELETED_RECORD = 'clientDeletedRecord',
  CLOSING_WITH_DISCOUNT = 'closingWithDiscount',
  CLIENT_WAS_DETAINED = 'clientWasDetained',
}

export const yclientsEvents: TSimpleStringObj = {
  // [YCLIENTS_EVENTS.CLIENT_DELETED_RECORD] clientDeletedRecord: 'Клиент удалил запись',
  [YCLIENTS_EVENTS.CLOSING_WITH_DISCOUNT]: 'Запись закрыта с большой скидкой',
  // [YCLIENTS_EVENTS.CLIENT_WAS_DETAINED]: 'Клиента задержали',
};

export enum CRM_EVENTS {
  RECORD_DONE = 'recordDone',
  RECORD_CANCELLED = 'recordCancelled',
}

export const crmEvents: TSimpleStringObj = {
  [CRM_EVENTS.RECORD_DONE]: 'Клиент пришел',
  [CRM_EVENTS.RECORD_CANCELLED]: 'Клиент отменил запись',
};

export enum BB_INTEGRATION_EVENTS {
  BAD_FEEDBACK = 'badFeedback',
  NOT_RECORDED_WHATSAPP = 'notRecordedWhatsapp',
}

export const bbIntegration: TSimpleStringObj = {
  [BB_INTEGRATION_EVENTS.BAD_FEEDBACK]: 'Плохой отзыв',
  // [BB_INTEGRATION_EVENTS.NOT_RECORDED_WHATSAPP]: 'Клиент не записан после диалога в вотсапе',
};

export const bbIntegrationI: TSimpleStringObj = {
  [BB_INTEGRATION_EVENTS.BAD_FEEDBACK]: 'Плохой отзыв I',
  // [BB_INTEGRATION_EVENTS.NOT_RECORDED_WHATSAPP]: 'Клиент не записан после диалога в вотсапе I',
};

export enum WORK_STATISTICS_EVENTS {
  MISSED_CALL_STATISTICS = 'missedCallsStatistics',
  WHATSAPP_RESPONSE_RATE = 'whatsappResponseRate',
  LOW_ENROLLED_PERCENTAGE = 'lowEnrolledPercentage',
}

export const workStatistics: TSimpleStringObj = {
  [WORK_STATISTICS_EVENTS.MISSED_CALL_STATISTICS]: 'Пропущенные звонки',
  [WORK_STATISTICS_EVENTS.WHATSAPP_RESPONSE_RATE]: 'Скорость ответа в Whatsapp',
  [WORK_STATISTICS_EVENTS.LOW_ENROLLED_PERCENTAGE]: 'Низкий процент записавшихся',
};

export const workStatisticsI: TSimpleStringObj = {
  [WORK_STATISTICS_EVENTS.MISSED_CALL_STATISTICS]: 'Пропущенные звонки',
  [WORK_STATISTICS_EVENTS.WHATSAPP_RESPONSE_RATE]: 'Скорость ответа в Whatsapp I',
  [WORK_STATISTICS_EVENTS.LOW_ENROLLED_PERCENTAGE]: 'Низкий процент записавшихся I',
};

export enum TASK_DATA_KEY_NAMES {
  ID = 'id',
  IS_ACTIVE = 'isActive',
  TASK_TYPE_KEY = 'taskType',
  WHEN_SEND = 'whenSend',
  SENDING_PERIOD = 'sendingPeriod',
  SENDING_TIME = 'sendingTime',
  FREQUENCY = 'frequency',
  SEND_WEEK_DAYS = 'sendWeekDays',
  MONTH_SEND_DAYS_KEY = 'monthSendDays',
  SEND_ON_LAST_DAY_OF_MONTH = 'sendOnLastDayOfMonth',
  DAY_WITHOUT_VISITING = 'daysWithoutVisiting',
  SELECTED_EVENT_TYPE = 'selectedEventType',
  SELECTED_TELEPHONY_TYPE = 'selectedTelephonyType',
  SELECTED_YCLIENTS_TYPE = 'selectedYclientsType',
  SELECTED_CRM_TYPE = 'selectedCrmType',
  DISCOUNT_AMOUNT = 'discountAmount',
  CLIENT_DELAYED = 'clientDelayed',
  TASK_NAME = 'taskName',
  TASK_DESCRIPTION = 'taskDescription',
  IS_ADD_CHECKLIST = 'isAddChecklist',
  CHECKLIST_ITEMS = 'checklistItems',
  SELECTED_BB_INTEGRATION_TYPE = 'selectedBBIntegrationType',
  SELECTED_WORK_STATISTICS_TYPE = 'selectedWorkStatisticsType',
  MISSED_CALL_PERCENTS = 'missedCallsPercents',
  THRESHOLD_WHATSAPP_RESPONSE_RATE = 'thresholdWhatsappResponseRate',
  THRESHOLD_LOW_ENROLLED_PERCENTAGE = 'thresholdLowEnrolledPercentage',
  SELECTED_CLIENT_EVENT = 'selectedClientEvent',
  NOT_RECORD = 'notRecord',
  LATE_REPLY = 'lateReply',
  SERVICES = 'services',
  STAFF = 'staff',
  VISITS = 'visits',
  REPORT_REQUIRED = 'reportRequired',
}

export const INITIAL_TIME = '10:00';
export const INITIAL_VALUE = '1';

export enum TASK_PREVIEW_TEXT {
  CLIENT_CARD = 'Карточка клиента',
  PHONE_NUMBER = 'Номер телефона',
  LAST_CONTACT = 'Последний контакт',
  CLIENT_WAS = 'Клиент был',
  BY_MASTER = 'У мастера',
  ON_SERVICE = 'На услуге',
  TOTAL_VISITS = 'Всего визитов/оплат',
  AVERAGE_CHECK = 'Средний чек',
  USUALLY_WALKS = 'Обычно ходит',
  CANCELED_RECORDS = 'Отменял записи',
}

export const DEFAULT_TASK_PREVIEW_PHONE_NUMBER = '+7 (999) 999-99-99';
export const DEFAULT_TASK_PREVIEW_EMPTY_VALUE = '-';

export const TASK_PREVIEW_CLIENT_ITEMS = [
  {
    title: TASK_PREVIEW_TEXT.PHONE_NUMBER,
    value: DEFAULT_TASK_PREVIEW_PHONE_NUMBER,
  },
  {
    title: TASK_PREVIEW_TEXT.LAST_CONTACT,
    value: '3 дня назад',
  },
  {
    title: TASK_PREVIEW_TEXT.CLIENT_WAS,
    value: '57 дней назад',
  },
  {
    title: TASK_PREVIEW_TEXT.BY_MASTER,
    value: 'Евгения',
  },
  {
    title: TASK_PREVIEW_TEXT.ON_SERVICE,
    value: 'Окрашивание бровей',
  },
  {
    title: TASK_PREVIEW_TEXT.TOTAL_VISITS,
    value: '15 / 50540₽',
  },
  {
    title: TASK_PREVIEW_TEXT.AVERAGE_CHECK,
    value: '3240₽',
  },
  {
    title: TASK_PREVIEW_TEXT.USUALLY_WALKS,
    value: 'Утром',
  },
  {
    title: TASK_PREVIEW_TEXT.CANCELED_RECORDS,
    value: '3 раза',
  },
];
